import React, {
  useState,
  ReactNode,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DrawerContainer } from "./../Drawer";
import { Icon } from "./../Icon";
import { SendingSheet } from "./SendingSheet";
import { MAIN_COLOR } from "./../../config";
import { AuthContext } from "../../auth/AuthProvider";
// import { exportLegacy } from "../../exportLegacy";
import styles from "./style.module.scss";

interface CheersLayoutProps {
  children: ReactNode;
}

export const CheersLayout: React.FC<CheersLayoutProps> = ({ children }) => {
  const location = useLocation();
  const [sending, setSending] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<string>();
  const navigate = useNavigate();
  const me = useContext(AuthContext);

  const handleStartSending = () => {
    setSending(true);
  };

  // //TODO:To remove
  // const singleExportLegacy = useCallback(async () => {
  //   exportLegacy();
  // }, []);
  // useEffect(() => {
  //   singleExportLegacy();
  // }, [singleExportLegacy]);

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setActivePage("home");
        break;
      case "/feed":
        setActivePage("feed");
        break;
      case "/analysis":
        setActivePage("analysis");
        break;
    }
  }, [location.pathname]);

  const configWithHiddenItemsOnMobile = {
    logo: {
      light: "/logo.png",
      dark: "/logo.png",
      hideOnMobile: true, //IMPORTANT
    },
    menu: {
      mainSection: {
        // label: "Main Section",
        options: [
          {
            icon: <Icon name="Home" />,
            label: "Home",
            value: "home",
          },
          {
            icon: (
              <img
                src="/img/send.png"
                alt="Send"
                className={styles.sendButton}
              />
            ),
            // icon: <Icon name="Send" size={42} style={{ color: "red" }} />,
            label: "Send",
            value: "send",
            hideOnTablet: true,
            hideOnDescktop: true,
            onClick: handleStartSending,
            notStateOption: true,
          },
          {
            icon: <Icon name="CodeScan" />,
            label: "Feed",
            value: "feed",
          },
          ...(me?.analysisAuthorization
            ? [
                {
                  icon: <Icon name="Trends" />,
                  label: "Analysis",
                  value: "analysis",
                },
              ]
            : []),
          // {
          //   icon: <Icon name="OutlineBasicsFormValidationCheckStar" />,
          //   label: "Approvals",
          //   value: "approvals",
          //   hideOnTablet: true, //IMPORTANT
          //   hideOnMobile: true, //IMPORTANT
          // },
        ],
      },
      adminSection: {
        // hideOnTablet: true, //IMPORTANT
        hideOnMobile: true, //IMPORTANT
        label: "",
        options: [
          {
            icon: (
              <img
                src="/img/send.png"
                alt="Send"
                className={styles.sendButton}
              />
            ),
            label: "Send",
            value: "send",
            onClick: handleStartSending,
            notStateOption: true,
          },
        ],
      },
    },
    // profile: {
    //   hideOnTablet: false, //Default value
    //   hideOnMobile: true, //IMPORTANT
    //   userName: "Nom Prénom",
    //   userJobTitle: "Project Manager",
    //   imageSource: "/avatar.jpg",
    //   logout: {
    //     label: "Log Out",
    //     onClick: handleLogout,
    //   },
    //   options: [
    //     { icon: <Icon name="AlarmBell" />, label: "Accessibility", href: "#" },
    //     { icon: <Icon name="AddCircle" />, label: "Marketplace", href: "#" },
    //     { icon: <Icon name="Pencil" />, label: "Board", href: "#" },
    //     { icon: <Icon name="Cog1" />, label: "Configuration", href: "#" },
    //   ],
    // },
  };

  const handleOptionChange = (v: string) => {
    setActivePage(v);
    switch (v) {
      case "home":
        navigate("/home");
        break;
      case "feed":
        navigate("/feed");
        break;
      case "analysis":
        navigate("/analysis");
        break;
    }
  };

  return (
    <>
      <DrawerContainer
        largeScreenDesign="rail" //"standard"
        mediumScreenDesign="rail"
        mobileScreenDesign="rail"
        {...configWithHiddenItemsOnMobile}
        mobilePosition="bottom"
        open={true}
        // closeDrawer={() => setOpen(false)}
        option={activePage}
        onOptionChange={(v) => handleOptionChange(v)}
        scrollableContent={false}
        primaryColor={MAIN_COLOR}
      >
        {children}
      </DrawerContainer>
      <SendingSheet open={sending} closeMe={() => setSending(false)} />
    </>
  );
};
